import React, { Component }  from 'react';
 import Container from 'react-bootstrap/Container';
 import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Megamenu from '../section/megamenu';
import { Space } from 'antd';

   class Header extends Component {
    
    render(){
    return(
      <>

      <div className='topBar'>
        <Container fluid>
          <Row>
            <Col lg="12">

                    <Nav  className="justify-content-center "
              // activeKey="/home"
              // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
             
              <Nav.Item>
              <Nav.Link >
                 <NavLink to="/grow-with-us"   activeclassname="active"><i class="fa fa-line-chart" aria-hidden="true"></i>  Grow with us</NavLink></Nav.Link>

               </Nav.Item>

                <Nav.Item>
                <Nav.Link >  <NavLink to="/impact" > <i class="fa fa-superpowers" aria-hidden="true"></i>  Impact </NavLink> </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link >  <NavLink to="/team" ><i class="fa fa-users" aria-hidden="true"></i>  Our team </NavLink> </Nav.Link>
              </Nav.Item>

               
              <Nav.Item>
                <Nav.Link eventKey="link-1"><NavLink to="/careers" ><i class="fa fa-briefcase" aria-hidden="true"></i>  Careers</NavLink> </Nav.Link>
              </Nav.Item>

                  
              <Nav.Item>
                <Nav.Link eventKey="link-1"><NavLink to="/school-newsletters" ><i class="fa fa-file-pdf-o" aria-hidden="true"></i>  School Newsletters</NavLink> </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="link-1"><NavLink to="/refer_form" ><i class="fa fa-retweet" aria-hidden="true"></i>  Refer</NavLink> </Nav.Link>
              </Nav.Item> */}
 
{/* 
              <Nav.Item>
                <Nav.Link eventKey="link-2"><NavLink to="/faculties" >Faculties</NavLink></Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                {/* <Nav.Link eventKey="link-2"><NavLink to="/" ><i class="fa fa-graduation-cap" aria-hidden="true"></i>  Alumni</NavLink></Nav.Link> */}
              </Nav.Item>
              <Nav.Item>
                {/* <Nav.Link eventKey="link-2"><NavLink to="/ " ><i class="fa fa-book" aria-hidden="true"></i>  E-learning</NavLink></Nav.Link> */}
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="link-2"><NavLink to="/ " > <i class="fa fa-search"></i></NavLink></Nav.Link>
              </Nav.Item> */}
               
            </Nav>

            </Col>
          </Row>
        </Container>
      </div>
        <Navbar collapseOnSelect expand="lg" >
      <Container fluid>
        <Navbar.Brand > <NavLink to="/ "> <Image src="assets/images/innovera/Innovera-Logo-white.png"  fluid/></NavLink></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav " >
          <Nav className="   nav">
             
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown" className='d-none' >
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
          {/* <NavLink to="/"   activeclassname="active">Home</NavLink> */}
         
          <NavLink to="/apply"   activeclassname="active">Apply</NavLink>
             <NavLink to="/visit"   activeclassname="active">Visit</NavLink>
             <NavLink to="/why-innovera"   activeclassname="active">Why Innovera</NavLink>
             <NavLink to="/parents"   activeclassname="active">Ideal Parent</NavLink>
             <NavLink to="/contact"   activeclassname="active">Contact Us</NavLink>
             <NavLink to="/our-branches"   activeclassname="active">Our Branches</NavLink>
            {/* <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link> */}

<NavDropdown title="Login" id="collasible-nav-dropdown" className='' >

 
    <NavDropdown.Item href="https://erp.innoveraschool.com/site/login " target="_blank">Staff</NavDropdown.Item>
    <NavDropdown.Item href="https://erp.innoveraschool.com/site/userlogin" target="_blank" >
    Student/Parent
    </NavDropdown.Item>
     
  </NavDropdown>
          </Nav>
          <Megamenu   display= "d-block" dblock = "d-sm-none"/>

        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Megamenu  display= "d-none" dblock = "d-sm-block"/>
    </>
           );
}

  }
export default Header;
