import React, { Component } from 'react';
//importing bootstrap 5 css
import { NavLink } from 'react-router-dom';
import Header from '../layout/header';
 
function Megamenu( props ) {
 
  return (
   
        <div className={`APP ${ props.display} ${ props.dblock}`}  >
      
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
        <div className="container-fluid">
        <a href="#" className="navbar-brand font-weight-bold d-block d-lg-none">About School</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div id="navbarSupportedContent" className="collapse navbar-collapse">
            <ul className="navbar-nav mx-auto">
           
            <li className="nav-item dropdown megamenu d-none">
                <a id="megamneu" href="#" pid="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle font-weight-bold text-uppercase dropdown-toggle">About School</a>
                <div aria-labelledby="dropdownMenuButton1" className="dropdown-menu border-0 p-0 m-0">
                <div className="container">
                    <div className="row bg-white rounded-0 m-0 shadow-sm">
                    <div className="col-lg-7 col-xl-8">
                        <div className="p-4">
                        <div className="row">
                            <div className="col-lg-6 mb-4">
                            <h6 className="font-weight-bold text-uppercase">MegaMenu heading</h6>
                            <ul className="list-unstyled">
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0">Unique Features</a></li>
                                <NavLink to="/speciality"   activeclassname="active">Speciality</NavLink>

                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Image Responsive</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Auto Carousel</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Newsletter Form</a></li>
                            </ul>
                            </div>
                            <div className="col-lg-6 mb-4">
                            <h6 className="font-weight-bold text-uppercase">MegaMenu heading</h6>
                            <ul className="list-unstyled">
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Unique Features</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Image Responsive</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Auto Carousel</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Newsletter Form</a></li>
                            </ul>
                            </div>
                            <div className="col-lg-6 mb-4">
                            <h6 className="font-weight-bold text-uppercase">MegaMenu heading</h6>
                            <ul className="list-unstyled">
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Unique Features</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Image Responsive</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Auto Carousel</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Newsletter Form</a></li>
                            </ul>
                            </div>
                            <div className="col-lg-6 mb-4">
                            <h6 className="font-weight-bold text-uppercase">MegaMenu heading</h6>
                            <ul className="list-unstyled">
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Unique Features</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Image Responsive</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Auto Carousel</a></li>
                                <li className="nav-item"><a href="" className="nav-link text-small pb-0 ">Newsletter Form</a></li>
                            </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div>
                    </div>
                </div>
                </div>
            </li>
 
            <li className="nav-item"> <NavLink to="/values"   activeclassname="active">Values</NavLink></li>
            <li className="nav-item"> <NavLink to="/about"   activeclassname="active">About us</NavLink></li>
            <li className="nav-item"> <NavLink to="/enroll"   activeclassname="active">Enroll now</NavLink></li>
            <li className="nav-item"> <NavLink to="/academics"   activeclassname="active">Academics</NavLink></li>
            <li className="nav-item"> <NavLink to="/beyond-academics"   activeclassname="active">Beyond academics</NavLink></li>
            <li className="nav-item"> <NavLink to="/life-at-innovera"   activeclassname="active">Life at innovera</NavLink></li>
            {/* <li className="nav-item"> <NavLink to="/parents"   activeclassname="active">Ideal Parent</NavLink></li> */}
         
            <li className="nav-item"> <NavLink to="/facilities"   activeclassname="active"> Facilities
</NavLink></li>
<li className="nav-item"> <NavLink to="/impressions"   activeclassname="active">Impressions</NavLink></li>
            {/* <li className="nav-item"> <NavLink to="/grow-with-us"   activeclassname="active">Grow With Us</NavLink></li> */}
             
            </ul>
        </div>
        </div>
        </nav>
        
       
       
  </div>
  );
}

export default Megamenu;