import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import Branches from '../components/section/branches';
import Faculty from '../components/section/faculties';
import Parents from '../components/section/parents';
 import config from '../constant';


class BranchMain extends Component {
    componentDidMount(){
        document.title = "Branches | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <Branches/>
             <Footer/>


        </div>
    );

}
    }
export default BranchMain;