import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
 import SubHeader from '../layout/subheader';
 import { NavLink } from 'react-router-dom';
 import Nav from 'react-bootstrap/Nav';
  import EnquiryForm from './enquiryform';
import ContactMainForm from './contact_main_form';
import Googlemap from './googlemap';
     
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const Contactform = () => {
 
       
     

        const [mailerState, setMailerState] = useState({
          name: "",
          phone:"",
          email: "",
          message: "",
        });
    
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
    
        const submitEmail = async (e) => {
           e.preventDefault();
          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"send/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                alert("Message Sent");
              } else if (resData.status === "fail") {
                alert("Message failed to send");
              }
            })
            .then(() => {
              setMailerState({
                email: "",
                name: "",
                phone:"",
                message: "",
              });
 
            });
            
        };
    
         return(


          <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Contact' subtitle='us'/>
      <Container  >
      <Row>
      <Col lg="9">
            
              <Container className="my-3 py-5 ">
 
                <Row>
                    <Col lg="12 " className='d-none'>
                    <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">Contact</span>
            <span className="greenBor"> Us</span>
          </h1>   


                        <Form  onSubmit={submitEmail}
>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="name " onChange={handleStateChange}
 name="name"  value={mailerState.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={handleStateChange}
           name="message"     value={mailerState.message}/>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" className='btn btn-success'  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                    </Col>
                    <Col  lg="12" className="contactUS">
                     {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBor"> Enquire Now</span>
          </h1>   */}


         
<h4 className="pt-3">Address</h4>
 <h4>Innovera School</h4>
 <p>M/s. SHIKSHA EDUCATION SOCIETY</p>

<p> BEHIND SAMRUDHI TRACTOR SHOWROOM, <br></br>
KADAM WAK VASTI, PUNE-SOLAPUR ROAD, PUNE, MAHARASHTRA - 412201

</p>
    <hr/>


      <h4>Email</h4>
<p><i className="fa fa-envelope"></i> <a href="mailto:info@innoveraschool.com ">info@innoveraschool.com  </a></p>

<hr/>


      <h4>Phone Office</h4>  
<p><i className="fa fa-mobile"></i> <a href="tel: +919168290611 "> 91682 90611 </a> |  <a href="tel: +917387332255"> 73873 32255</a> | <a href="tel:8956217899">8956217899</a></p>

                      </Col>


                     
                </Row>

                <Row>
                      <Googlemap/>

                     
                 </Row>
            </Container>
            </Col>

<Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact"  className="bt-sidebar"> &nbsp;Enquiry form
 </NavLink>  </li>

<li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Admission

 </NavLink></li>
<li><NavLink to="/grow-with-us"  className="bt-sidebar "> &nbsp;Franchise

 </NavLink></li>
<li><NavLink to="/grow-with-us"  className="bt-sidebar "> &nbsp;Partner with us 


 </NavLink></li>
<li><NavLink to="/grow-with-us"  className="bt-sidebar "> &nbsp;Conduct Session
 

 </NavLink></li>
</ul>
</div>


 
 <div className="sidebar-item link">
<h4>Call Us</h4>
<hr></hr>
 <ContactMainForm/>
</div>
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
            
         )
    }


export default Contactform;