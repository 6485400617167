import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import config from '../constant';
import { Helmet, HelmetProvider } from "react-helmet-async";


class About extends Component {
    // componentDidMount(){
    //     document.title = "About | " + config.SIE_NAME
    //   }
        render(){
    return(
        <div>
            <HelmetProvider>
             <meta charSet="utf-8" />
                <title> Innovera School - A CBSE School with Excellent Infrastructure </title>
	<meta name="description" content="Innovera School is a CBSE school with excellent infrastructure that offers spacious and ventilated classrooms, lab, library and recourse room." />
	<link rel="canonical" href="https://innoveraschool.com/about/?PSE" />   
   </HelmetProvider>
            <Header/>
            <AboutUs/>
             <Footer/>


        </div>
    );

}
    }
export default About;