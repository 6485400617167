import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import ImpactForm from './impactForm';
import ContactMainForm from './contact_main_form';



class Impact extends Component {
        render() {

    return(
        <div>
             <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Impact ' subtitle=' '/>
            <Container  >
      <Row>
      <Col lg="9">
              
      

             
 
             <Container className='pt-5 mb-5 growSection  lifeAtInovera'>
             <h2  className="blueBg mb-4  text-center"  >Awareness program
</h2>

          <Row className='py-4'>
            <Col lg="4" sm="4" >
            <Image src='assets/images/innovera/impact/Grain-Donation.jpg' fluid/>
            <h4>Grain donation</h4>
            </Col>
            <Col lg="8" sm="8">
                <p>Before Diwali we encourage children to contribute and collect. The contribution goes to the community people who are in need. The collection, the segregation and the distribution is entirely handled by children of a particular class. We feel proud that most of our parents contribute wholeheartedly for the same. Children get a valuable lesson that before we sit to feast share a portion with the people who need it more.

</p>
            </Col>
 
          </Row>
          <hr></hr>

           <Row className='py-4'>
            <Col lg={{ order:2 , span:4 }} sm="4" >
            <Image src='assets/images/innovera/impact/Share-Fair.jpg' fluid/>
            <h4>Share fair</h4>
            </Col>
            <Col lg={{ order:1 , span:8 }}  sm="8">
            <p>This is one of the initiative initiated by a school parent, this encourages children to learn to 'use the used'. So a fair which encourages to bring their used toys, clothes, shoes, etc. which are in good conditions and let it be picked up by anyone who thinks they need it.</p>
            
            </Col>
           </Row> 
           <hr></hr>


           <Row className='py-4'>
             <Col lg="4" sm="4" >
            <Image src='assets/images/innovera/impact/free_yoga2.jpg' fluid/>
            <h4>Free Yoga at school</h4>
            </Col>
            <Col lg="8" sm="8" >
                <p>To encourage the villagers and community towards Yoga, the school has given its open hall for the daily practice of Yoga. Everyday around hundred people flock from nearby areas to participate in the daily Yoga session. An encouraging sight for our children and parents to adopt Yoga as a way of life.
</p>
            </Col>

          </Row>  
          <hr></hr>

           <Row className='py-4'>
 
            <Col lg={{ order:2 , span:4 }} sm="4" >
            <Image src='assets/images/innovera/impact/Toastmasters-Club.jpg' fluid/>
            <h4>Toastmasters club
 </h4>
            </Col>
            <Col lg={{ order:1 , span:8 }}  sm="8">
            <p>Innovera School is the only school to have its own Toastmasters International Club in school-a leadership and public speaking skill development platform. Objective to open one is to encourage and bring a habit of continuous learning in the parents and team members, following our value to be a lifelong learner.</p>
            </Col>
          </Row> 
          <hr></hr>


          <Row className='py-4'>
             <Col lg="4" sm="4" >
            <Image src='assets/images/innovera/impact/Foster-Talk.jpg' fluid/>
            <h4>Foster Talks</h4>
            </Col>
            <Col lg="8" sm="8" >
                <p>We invite, influencers, professionals and guides to talk to our children. They bring to them a new perspective to learning, and growing. Choose a career and knowing its process, knowing the subject and criteria needed for the same, the whole
</p>
            </Col>

          </Row>  
          <hr></hr>

           <Row className='py-4'>
 
            <Col lg={{ order:2 , span:4 }} sm="4" >
            <Image src='assets/images/innovera/impact/Prabhat-Pheri.jpg' fluid/>
            <h4>Prabhat pheri

 </h4>
            </Col>
            <Col lg={{ order:1 , span:8 }}  sm="8">
            <p>The school is very sensitive towards the safety of every child, hence as an awareness to the local villagers and passerbys, the school children had taken a Prabhat Pheri, an awareness rally, which also had slogans and Nukkad Nataks to educate parents for not giving vehicles to underage children.

</p>
            </Col>
          </Row> 
          <hr></hr>

          <Row className='py-4'>
             <Col lg="4" sm="4" >
            <Image src='assets/images/innovera/impact/Puberty-Session.jpg' fluid/>
            <h4>Puberty session
</h4>
            </Col>
            <Col lg="8" sm="8" >
                <p>Guiding girls and boys about their physical, biological and emotional development is key to helping them have a smooth movement from childhood to adolescence, the school does sessions for children and parents on best practices to handle puberty and growing up.

</p>
            </Col>

          </Row>  
          <hr></hr>
 
           <Row className='py-4'>
 
            <Col lg={{ order:2 , span:4 }} sm="4" >
            <Image src='assets/images/innovera/impact/Parent-Session.jpg' fluid/>
            <h4>Parent Session


 </h4>
            </Col>
            <Col lg={{ order:1 , span:8 }}  sm="8">
            <p>A very frequent and favourite interaction for our parents is the parenting sessions that we conduct at school. It’s a one of a kind session, where a lot of experience sharing and expert inputs are contributed, thus making these sessions the best time a parent may spend at school.
</p>
            </Col>
          </Row> 
            </Container>
      
            </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li> <NavLink to="/values"  className="bt-sidebar"> &nbsp;Values </NavLink>  </li>

 <li><NavLink to="/impressions"  className="bt-sidebar "> &nbsp;Impression</NavLink></li>
 <li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Enroll Now</NavLink></li>
 
</ul>
</div>
<div className="sidebar-item link">
<h4>Call Us</h4>
<hr></hr>
 {/* <ImpactForm/> */}
 <ContactMainForm/>

</div>
</div>

<div>
 </div>
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default Impact;