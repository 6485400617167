import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Beyond from '../components/section/beyond_academics';
import Cbse from '../components/section/cbse';
import Infrastructure from '../components/section/infrastructure';
import LifeAtInnovera from '../components/section/life_at_innovera';
  import config from '../constant';

  import { Helmet, HelmetProvider } from "react-helmet-async";

class InfraMain extends Component {
    // componentDidMount(){
    //     document.title = "Why Innovera | " + config.SIE_NAME
    //   }
        render(){
    return(
        <div>
            
<HelmetProvider>
             <meta charSet="utf-8" />
                <title>  Innovera School - School Facilities</title>
	<meta name="description" content="Innovera School provides an excellent learning environment for students. The school has a play ground of approx 8000 sq m and an open hall for sports and social activities." />
	<link rel="canonical" href="https://innoveraschool.com/facilities?PSE" />   
   </HelmetProvider>
            <Header/>
            <Infrastructure/>
             <Footer/>


        </div>
    );

}
    }
export default InfraMain;