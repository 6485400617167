import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';


class DeliverPage extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Deliver Inspiration' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="   growSection  impressions">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5  "  rounded>Deliver Inspiration </h2>
                  </Col>
  
                  </Row>
                <Row>        
                {[...Array(9)].map((e, i) => {
                             return      <Col lg='4'   key={i + 1} >
              <div className="productDiv" style={{  backgroundImage: `url(assets/images/innovera/deliver_inspiration/${i+1 }.jpeg )`   }} >
               
				         </div>
              </Col>                        })}


                         </Row>
      
</Container>

         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/library"  className="bt-sidebar"> &nbsp;Library

 </NavLink>  </li>

<li><NavLink to="/ "  className="bt-sidebar "> &nbsp;Composit Lab


 </NavLink></li>
<li><NavLink to="/ "  className="bt-sidebar "> &nbsp;Computer lab



 </NavLink></li>
<li><NavLink to="/resource-room"  className="bt-sidebar "> &nbsp;Resource Room



 </NavLink></li>
<li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Enroll Now
 

 </NavLink></li>
</ul>
</div>


 
 
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default DeliverPage;