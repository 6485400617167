import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
 
class AboutHomepage extends Component {

    render(){
        return(
            <div>

                <Container className=" py-5 aboutUs ">
                <h1 className=" pb-4  mt-0 mb-0">
            <span className="greenBg">About</span>
            <span className="blueBor">  Innovera</span>
          </h1>
                    <Row>
                        <Col lg="12">
                          <h4 className='text-center'>  Innovera school believes in  </h4>
                        {/* <ul>
                        <li>Building strong relationship</li> 
<li>Ensuring every child is catered to</li> 
<li>In having team members who are child sensitive</li> 
<li>Emparting values </li> 
<li>Learning can happen anywhere </li> 
<li>Indian culture and its values </li> 
<li>Partnering with the parents for the growth of the children</li> 
<li>Culminating technology and traditions                       </li> 
</ul>      */}

<Image src="assets/images/innovera/Innovera-website-About-Us.png" className='d-none d-sm-block' fluid/>
<Image src="assets/images/innovera/Innovera-website-About-Us-mobile.png" className='d-sm-none d-xs-block' fluid/>
                        </Col>
                    </Row>
                        
                    
                    <Row className="pt-5 text-center aboutCircle">
                        <Col lg="3">
                        <NavLink to="/team" >
                           <Image src="assets/images/innovera/Circle-1.png" fluid/>
                           
                           <h4>Pillars</h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">
                        <NavLink to="/impressions" >

                        <h4 className="d-none d-sm-block">Achievement</h4>
                        {/* <div className='blueCircle' style={{ 
            backgroundImage: `url(assets/images/hotel/bannar_04.jpg)` 
          }} >
                           </div> */}
                      <Image src="assets/images/innovera/Circle-2.png" fluid/>

                           <h4 className="d-block d-sm-none">Achievement </h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">

                        {/* <div className='greenCircle' style={{ 
            backgroundImage: `url(assets/images/hotel/bannar_04.jpg)` 
          }} >
                           </div> */}
               <NavLink to="/impressions" >

                    <Image src="assets/images/innovera/Circle-3.png" fluid/>

                           <h4  className="d-none d-sm-block">Impressions </h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">
                        <NavLink to="/life-at-innovera" >

                        <h4>Life at innovera</h4>

                        <Image src="assets/images/innovera/Circle-4.png" fluid/>

                           <h4 className="d-block d-sm-none"> Life at innovera</h4>
                         </NavLink>
                          </Col>
                       {/* <Col lg="12">
                       <Image src="assets/images/hotel/bannar_04.jpg" alt="Green county" fluid  rounded/>
                                             </Col> */}
                    </Row>

                    <Row className='pt-5'>
                      <Col className='text-center'>
                      

                     <a href='https://erp.innoveraschool.com/site/quickEnquiry' target='_blank'> <button type="button" class="btn btn-success btn-lg"> Admission Enquiry</button></a>
                      </Col>
                    </Row>
                </Container>
                 

         </div>
        );
    }

}

export default AboutHomepage;
 