import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Grow from '../components/section/grow';
import Owlslider from '../components/section/owlslider';
import Values from '../components/section/values';
import config from '../constant';

class GrowMain extends Component {
    componentDidMount(){
        document.title = "Grow | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>

            <Grow/>
             <Footer/>


        </div>
    );

}
    }
export default GrowMain;