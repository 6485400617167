import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Beyond from '../components/section/beyond_academics';
import Career from '../components/section/career';
import Cbse from '../components/section/cbse';
import Infrastructure from '../components/section/infrastructure';
import LifeAtInnovera from '../components/section/life_at_innovera';
  import config from '../constant';
import CurrentOpening from '../components/section/current_openings';


class CurrentOpeningsMain extends Component {
    componentDidMount(){
        document.title = "Current Openings | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <CurrentOpening/>
             <Footer/>


        </div>
    );

}
    }
export default CurrentOpeningsMain;