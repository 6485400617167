import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Beyond from '../components/section/beyond_academics';
import Career from '../components/section/career';
import Cbse from '../components/section/cbse';
import Impact from '../components/section/impact';
import Infrastructure from '../components/section/infrastructure';
import LifeAtInnovera from '../components/section/life_at_innovera';
  import config from '../constant';


class ImpactMain extends Component {
    componentDidMount(){
        document.title = "Impact | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <Impact/>
             <Footer/>


        </div>
    );

}
    }
export default ImpactMain;