import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import ContactMainForm from './contact_main_form';
import { Link } from "react-scroll";

class CurrentOpening extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Current Openings' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-5 text-center currentOpenings ">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
           <Row>
                  
                  <Col lg="12" className="growSection">
                  <h2  className="blueBg mb-4  "  rounded>Current Openings </h2>
  
                  <h4>Innovera School is looking to expand its family and is looking for adding few new members.</h4>
  
                  
                  </Col>
  
                  </Row>
                <Row>
                <Container fluid >
                <Row>        
              <Col lg="12" className='mt-4'>

              <div className="growthDiv ">
                <div
                  className="growthImg1"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-2.jpg)`,
                  }}
                >
                  <div className="growthCaption  p-3  ">
                     <h4 className="text-center   mainText "  ><span>1. Science Teacher (Middle School & High School)
 
                     </span></h4> 
                     <p className='text-white text-left'><b>Qualifications:&nbsp;</b>  M.Sc. or B.Sc. in Science (specializing in Chemistry, Biology, or Physics) and a B.Ed. degree.<br></br><br></br>
                     <b>Skills:&nbsp;</b> Strong knowledge of the subject, ability to engage students in interactive learning, and excellent classroom management skills.
Experience: Prior teaching experience is preferred, but freshers with a passion for science education are also welcome
                     </p>
                     <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >
                   Apply</Link>
                  </div>
                </div>
              </div>
                {/* <div className='BranchBox'>
              <h3 className="text-center blink mainText "  ><span>Do you have a land you want to lease us ? </span></h3>

                <h4  className="text-center  ">  Connect with us    </h4>
                    
                </div> */}
              </Col>
              
              <Col lg="12" className='mt-4'> 
              <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-1.jpg)`,
                  }}>
                   <div className="growthCaption p-4 text-center">
                  <h4 className="text-center  mainText greenFont "  ><span>Computer Teacher (Middle School & High School)   </span></h4>
                  <p className='text-white'><b>Qualifications:&nbsp; </b>
B.Sc. in Computer Science, BCA, or a Bachelor of Information Technology, OR
Graduate degree in any subject with Mathematics and a two-year Diploma in Computer Engineering/IT from an institution recognized by AICTE/University, OR
Graduate degree in any subject with Mathematics and at least a one-year Diploma in Computer Applications from an institution recognized by AICTE/University.<br></br><br></br>
<b>Skills:&nbsp; </b> Proficiency in computer science concepts, ability to teach programming and software applications, and strong problem-solving skills.
Experience: Candidates with a strong academic background and relevant certifications in computer science education are encouraged to apply.
                  </p>
                  <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >Apply </Link>
                  </div>
                </div>
              </div>
              

              </Col>

              <Col lg="12" className='mt-4'>
              <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-2.jpg)`,
                  }}>
                   <div className="growthCaption p-3 text-center">
                  <h4 className="text-center  mainText "  ><span>Preschool Teachers (Preschool)</span></h4>

                  <p className='text-white'><b>Qualifications:&nbsp; </b>
                  ECCE (Early Childhood Care and Education), MTTC (Montessori Teacher Training Course), TTC (Teacher Training Course), or any equivalent certification.<br></br><br></br>
<b>Skills:&nbsp; </b> Passion for early childhood education, ability to create a nurturing and stimulating environment, and excellent communication skills in English.
Experience: Open to both freshers and experienced educators who have a genuine interest in fostering young minds.
                  </p>
                   <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
            className="btn btn-success text-center" activeclassname="active"
          >Apply</Link>
                  </div>
                </div>
              </div>
              

              </Col>
                        </Row>
            </Container>

                </Row>

                </Container>

            

          <div className="highlights pb-5" id="connectivity">
            <Container >

             
          
          <h5 className="text-center pb-3">So, if you know someone who is in search and willing to join please let us know on the following number Mo. 9168290611 or can send the resume on mail@innoveraschool.com <br></br>
          INNOVERA SCHOOL, LONI KALBHOR</h5>

          <Row><Col lg={{span:"6" , offset:3 }}>
            
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-10}
            duration={100}
           >  <div className="BranchBox3  text-center">APPLY NOW  </div> </Link>  </Col>
          </Row>
          <Row id='contact'>
            <Col  >
                  <CareerForm/>
            </Col>
          </Row>
 
                
            </Container>
            </div>
 
         
         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/team"  className="bt-sidebar"> &nbsp;Team
 </NavLink>  </li>

{/* <li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Application form

 </NavLink></li>
<li><NavLink to="/careers"  className="bt-sidebar "> &nbsp;Current openings


 </NavLink></li> */}
<li><NavLink to="/life-at-innovera"  className="bt-sidebar "> &nbsp;Day at Innovera


 </NavLink></li>
<li><NavLink to="/impressions"  className="bt-sidebar "> &nbsp;Our Culture
 

 </NavLink></li>
</ul>
</div>


 
 <div className="sidebar-item link" >
<h4>Call Us</h4>
<hr></hr>
 <ContactMainForm/>
</div>
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default CurrentOpening;