import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'


const cbse = [
    {
        heading:"Permission Letters",
        backend:"View Permission Letters"
    },
    {
        heading:"Permission letter 2",
        backend:"View Permission letter 2"
    },
    {
        heading:"NOC",
        backend:"View NOC"
    },
    {
        heading:"School Society Permission Letters",
        backend:"View School Society Permission Letters"
    },
    {
        heading:"EPTA",
        backend:"View EPTA"
    }
]
class Cbse extends Component {
        render() {

    return(
        <div>
            <Container className="py-5 text-center">
            <h1 className=" pb-5  mt-0 mb-0">
             <span className="blueBor"> School Docs</span>
          </h1>            
                 <Row>
                    <Col lg="4">
                <a href='assets/images/innovera/cbse/Manyata-Patra.pdf' target="_blank"> 
                    <div className="circular-sb">
                        Permission Letters
                        <span className="circDesc"> View Permission Letters</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div> 
                        </a>
                    </Col>
                    <Col lg="4">
                    <a href='assets/images/innovera/cbse/Manyata-Patra-1.pdf' target="_blank"> 
                    <div className="circular-sb">
                        Permission Letters 2
                        <span className="circDesc"> View Permission Letters 2</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div> 
                        </a>
                         </Col>
                    <Col lg="4"> 
                    <a href='assets/images/innovera/cbse/Noc.pdf' target="_blank"> 
                    <div className="circular-sb">
                          NOC
                        <span className="circDesc"> View NOC</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                        </div>
                        </a>
                    </Col>
                </Row>
                <Row>
                <Col lg="4">
                <a href='assets/images/innovera/cbse/Trust Certificate.pdf' target="_blank"> 
                <div className="circular-sb">
                School Society Permission Letters

                        <span className="circDesc"> View School Society Permission Letters
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>
                <Col lg="4">    
                <a href='assets/images/innovera/cbse/EPTA_24.pdf' target="_blank"> 

                <div className="circular-sb">  EPTA  
                 <span className="circDesc"> View EPTA
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>

                                                               <Col lg="4">    
          <a href='assets/images/innovera/cbse/SMC-Profile-2024-2027.pdf' target="_blank"> 

                <div className="circular-sb">  SMC  
                 <span className="circDesc"> View SMC
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>
                
                </Row>



                <Row>
                <Col lg="4">
                <a href='# '> 
                <div className="circular-sb">
                Building safety

                        <span className="circDesc"> View Building safety
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                     </Col>
                <Col lg="4">    
                <a href='assets/images/innovera/cbse/fire_certi.jpg'  target="_blank"  > 

                <div className="circular-sb">  Fire Safety  
                 <span className="circDesc"> View Fire Safety
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>


                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/Trust Certificate2.pdf' target="_blank"  > 

                <div className="circular-sb">  Trust Certificate
                 <span className="circDesc"> View Trust Certificate
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>


                                      
                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/Health Sanitation.pdf'  target="_blank" > 

                <div className="circular-sb">  Health Sanitation
                 <span className="circDesc"> View Health Sanitation
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>
 
                
                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/Affiliation.pdf'   target="_blank"> 

                <div className="circular-sb">  Affiliation
                 <span className="circDesc"> View Affiliation
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>


                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/board_result.pdf'  target="_blank"  > 

                <div className="circular-sb">  Result X 23-24
                 <span className="circDesc"> View Result X 23-24 
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>



                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/IEPL letter head pdf.pdf'  target="_blank" > 

                <div className="circular-sb">  Fees Structure
                 <span className="circDesc"> View Fees Structure
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>

                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/shiksha_letter_head.pdf'  target="_blank" > 

                <div className="circular-sb">  Fees Structure(Senior)
                 <span className="circDesc"> View Fees Structure
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>


                                      <Col lg="4">    
                <a href='assets/images/innovera/cbse/Mandatory_Disclosure_Details.pdf'  target="_blank" > 

                <div className="circular-sb">  Mandatory Disclosure
                 <span className="circDesc"> View Mandatory Disclosure
                        </span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div>
                        </a>
                                      </Col>
                </Row>


            </Container>
        </div>
    );
}
    }
export default Cbse;