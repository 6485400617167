import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';
import CareerForm from './careerform';
import ContactMainForm from './contact_main_form';
import Sidebar from '../layout/sidebar';

// --------------------------------------------

export const schoolImg = [
    {
      imgUrl: 'assets/images/innovera/Banner_5.jpg',
  },
{
        imgUrl: 'assets/images/innovera/library/5.jpeg',
  },
  {
    imgUrl: 'assets/images/innovera/school/lab.jpg',
},
  
]

export const smartclassImg = [
    {
      imgUrl: 'assets/images/innovera/school/classroom1.jpg',
  },
{
        imgUrl: 'assets/images/innovera/school/classroom2.jpg',
  },
  {
    imgUrl: 'assets/images/innovera/school/smartclass2.jpeg',
},
  
]
// --------------------------------------------

export const ClassImg = [
    {
      imgUrl: 'assets/images/innovera/school/smartclass4.jpeg',
  },
{
        imgUrl: 'assets/images/innovera/school/smartclass5.jpeg',
  },
  {
    imgUrl: 'assets/images/innovera/school/smartclass6.jpeg',
},
  
]
// --------------------------------------------


class AcademicsPage extends Component {
    render() {

return(
    <div> 

      <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Academics' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className="py-4  growSection academics">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
                <Row>
                  
                <Col lg="12">
                <h2  className="blueBg my-4  text-center"  rounded>Staff Quality </h2>

               <h5> 
                <ul>
                  <li>Love for teaching and passion to experiment</li>
                     <li>Passion to serve and grow</li>
                     <li>Ability to take decision and take ownership</li>
                 </ul>
                 </h5>

                
                </Col>

                <Col lg="12">
                <h2  className="blueBg my-4  text-center"  rounded>Curriculum  </h2>
                
                <h5>The school is affilited to the CBSE board, and the curriculum being followed is in compliance with the board needs. </h5>

                
                </Col>

               

                </Row>

                 

            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5  "  rounded>School </h2>
                  </Col>
  
                  </Row>
                <Row>        
                {schoolImg.map( (val , i) => (  

              <Col lg='4'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
					        
				         </div>
              </Col>                         )  )}


                         </Row>
             

            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5"  rounded>Smartclass</h2>
                  </Col>
  
                  </Row>
                <Row>        
                {ClassImg.map( (val , i) => (  

              <Col lg='4'  key={i}>
              <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
					        
				         </div>
              </Col>                         )  )}


                         </Row>
           
<Row>
      
      <Col lg="12">
      <h2  className="blueBg text-center  my-5"  rounded>Classroom</h2>
      </Col>

      </Row>
    <Row className='mb-4'>        
    {smartclassImg.map( (val , i) => (  

  <Col lg='4'  key={i}>
  <div className="productDiv" style={{  backgroundImage: `url( ${val.imgUrl} )`   }} >
                
             </div>
  </Col>                         )  )}


             </Row>
</Container>

         </Col>
         <Col lg="3">
         <Sidebar/>


         </Col>

         </Row>
         
         </Container>

         </div>
    );
}
    }
export default AcademicsPage;