import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import EnquiryForm from './enquiryform';


class AboutUs extends Component {

    render(){
        return(
            <div>
                 <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='About' subtitle='us'/>
      <Container  >
      <Row>
      <Col lg="9">

                <Container className=" py-5 aboutUs ">
                    {/* <h1 className=" pb-4  mt-0 mb-0"><span className="greenBor">Know More About Us</span></h1> */}
                    <Row>
                        <Col lg="12">
                        <h4 className='text-center'>  Innovera school believes in  </h4>
                        {/* <ul>
                        <li>Building strong relationship</li> 
<li>Ensuring every child is catered to</li> 
<li>In having team members who are child sensitive</li> 
<li>Emparting values </li> 
<li>Learning can happen anywhere </li> 
<li>Indian culture and its values </li> 
<li>Partnering with the parents for the growth of the children</li> 
<li>Culminating technology and traditions                       </li> 
</ul>              */}

<Image src="assets/images/innovera/Innovera-website-About-Us.png" className='d-none d-sm-block' fluid/>
<Image src="assets/images/innovera/Innovera-website-About-Us-mobile.png" className='d-sm-none d-xs-block' fluid/>

                        </Col>
                    </Row>
                        
                    
                    <Row className="pt-5 text-center aboutCircle">
                        <Col lg="3">
                        <NavLink to="/team" >

                           <Image src="assets/images/innovera/Circle-1.png" fluid/>
                           
                           <h4>Pillars</h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">
                        <NavLink to="/academics" >

                        <h4 className="d-none d-sm-block">Achievement</h4>
                        {/* <div className='blueCircle' style={{ 
            backgroundImage: `url(assets/images/hotel/bannar_04.jpg)` 
          }} >
                           </div> */}
                      <Image src="assets/images/innovera/Circle-2.png" fluid/>

                           <h4 className="d-block d-sm-none">Achievment </h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">
                        <NavLink to="/alumni" >

                        {/* <div className='greenCircle' style={{ 
            backgroundImage: `url(assets/images/hotel/bannar_04.jpg)` 
          }} >
                           </div> */}
                    <Image src="assets/images/innovera/Circle-3.png" fluid/>

                           <h4  className="d-none d-sm-block">Hear from our student </h4>
                           </NavLink>
                          </Col>
                        <Col lg="3">
                        <NavLink to="/life-at-innovera" >

                        <h4> Life at innovera</h4>

                        <Image src="assets/images/innovera/Circle-4.png" fluid/>

                           <h4 className="d-block d-sm-none">  Life at innovera</h4>
                           </NavLink>
                          </Col>
                       {/* <Col lg="12">
                       <Image src="assets/images/hotel/bannar_04.jpg" alt="Green county" fluid  rounded/>
                                             </Col> */}
                    </Row>
                </Container>
                </Col>
                <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>


<li> <NavLink to="/team"  className="bt-sidebar"> &nbsp;Our Team
 </NavLink>  </li>

<li><NavLink to="/facilities"  className="bt-sidebar "> &nbsp;Facilties

 </NavLink></li>
<li><NavLink to="/impact"  className="bt-sidebar "> &nbsp;Impacts


 </NavLink></li>
<li><NavLink to="/why-innovera"  className="bt-sidebar "> &nbsp;Why Innovera


 </NavLink></li>
<li><NavLink to="/life-at-innovera"  className="bt-sidebar "> &nbsp;life at Innovera 


 </NavLink></li>
 
</ul>
</div>
</div>

         </Col>

         </Row>
         
         </Container>

         </div>
        );
    }

}

export default AboutUs;