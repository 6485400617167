import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Apply from '../components/section/apply';
import Beyond from '../components/section/beyond_academics';
import Cbse from '../components/section/cbse';
import Visit from '../components/section/visit';
 import config from '../constant';


class VisitMain extends Component {
    componentDidMount(){
        document.title = "Visit | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <Visit/>
             <Footer/>


        </div>
    );

}
    }
export default VisitMain;