import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

class Beyond extends Component {
        render() {

    return(
        <div>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Beyond' subtitle='Academics'/>
      <Container  >
      <Row>
      <Col lg="9">

            <Container className="py-5 text-center beyondIcons">
            <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Beyond</span>
            <span className="blueBor"> Academics</span>
          </h1>            
          <p>
          Exposure to different environment stimuli can nurture young and impressionable minds. To know any child’s strength, we focus on the subjects apart from the academics, to ensure overall development.
          </p>
              <Row className='py-4' >
                <Col sm="6" lg="3">
                <Image src='assets/images/innovera/yoga.png' alt="" fluid />
                <h4>Yoga</h4>
                 </Col>
                <Col  sm="6" lg="3">   
                <Image src='assets/images/innovera/live-music.png'  alt="" fluid /> 
                <h4>Music and Dance</h4>
                  </Col>
                <Col  sm="6" lg="3"> 
                 <Image src='assets/images/innovera/art.png' alt="" fluid /> 
                 <h4>Art and Craft</h4>
                </Col>
                <Col  sm="6" lg="3"> 
                 <Image src='assets/images/innovera/sports.png' alt="" fluid />
                 <h4>Various Sports</h4>
                 </Col>
            </Row>

                <Row className='pb-4'>
                <Col sm="6" lg="3">  <Image src='assets/images/innovera/taekwondo.png' alt="" fluid /> 
                <h4>Martial Art</h4>
                </Col>
                <Col  sm="6" lg="3">   <Image src='assets/images/innovera/rotary.png'  alt="" fluid /> 
                <h4>Rotary Interact Club</h4>

                  </Col>
                <Col  sm="6" lg="3">  <Image src='assets/images/innovera/mirror-ball.png' alt="" fluid />
                <h4>Toastmasters Club</h4>
 </Col>
                <Col  sm="6" lg="3">  <Image src='assets/images/innovera/foster.png' alt="" fluid />
                
                <h4>Foster Talks</h4> </Col>
            </Row>
            </Container>

        <div className='grayBg'>
            <Container className='py-5'>
            <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">We prepare</span>
            <span className="greenBor"> our students</span>
          </h1> 
                <Row>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/beyonds_academics/Tomorrows-leader.jpg" />
                    <Card.Body>
                        <Card.Title> Tomorrows' leaders </Card.Title>
                        <Card.Text>
                        Leaders are not born they are made. We raise and train our kids through academics, athletics, values etc. to be tomorrows' leaders.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/beyonds_academics/Strong-Bond.jpg" />
                    <Card.Body>
                        <Card.Title> Strong bond </Card.Title>
                        <Card.Text>
                        Children spend most of their day time at school. At Innovera we create strong bond between students, parents and team members which remains strong forever.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/beyonds_academics/Critical-Thinking.jpg" />
                    <Card.Body>
                        <Card.Title> Critical thinkers'</Card.Title>
                        <Card.Text>
                        We shape our students to make a difference with their actions and thoughts. We acclimatize them for real world.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                    <Col lg="6">
                    <Card>
                    <Card.Img variant="top" src="assets/images/innovera/beyonds_academics/Making-Difference.jpg" />
                    <Card.Body>
                        <Card.Title>Pioneering a Change</Card.Title>
                        <Card.Text>
                        We shape our students to make a difference with their actions, thinking in the entire world. We make them ready to face the world.
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>
            </div>

            </Col>
                <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li> <NavLink to="/alumni"  className="bt-sidebar"> &nbsp;Alumni

 </NavLink>  </li>

<li><NavLink to="/impressions"  className="bt-sidebar "> &nbsp;Events
 </NavLink></li>

<li><NavLink to="/parents"  className="bt-sidebar "> &nbsp;Parents Involvement
 </NavLink></li>


<li><NavLink to="/values"  className="bt-sidebar "> &nbsp;Values at school
 </NavLink></li>

</ul>
</div>
</div>

         </Col>

         </Row>
         
         </Container>

         </div>

     );
}
    }
export default Beyond;