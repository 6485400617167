import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Cbse from '../components/section/cbse';
 import config from '../constant';
import Newsltr from '../components/section/newsltr';


class NewsLetterMain extends Component {
    componentDidMount(){
        document.title = "School Newsletters | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <Newsltr/>
             <Footer/>


        </div>
    );

}
    }
export default NewsLetterMain;