import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

class Growth extends Component {
  render() {
    return (
      <div className="growthSection" style={{  backgroundImage: `url(assets/images/innovera/Doodle-1.jpg)`, }}>
        <Container fluid className=" text-center ">
          <Row  >
            <Col sm="6" lg="6" className="px-0">
            <NavLink to="/grow-with-us" activeclassname="active"> 
              <div className="growthDiv">
                <div
                  className="growthImg1"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-1.jpg)`,
                  }}
                >
                  <div className="growthCaption">
                    <h4>Grow With us</h4>
                    <div className="btn btn-success" >Read More </div> 
                  </div>
                </div>
              </div>
              </NavLink>
            </Col>
            <Col sm="6" lg="6" className="px-0">
            <a href='assets/images/innovera/cbse/Affiliation.pdf' target="_blank" activeclassname="active" >
            <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-2.jpg)`,
                  }}
                >
                  <div className="growthCaption">
                    <h4>CBSE</h4>
                    <div className="btn btn-success" >Read More</div>
                  </div>
                </div>
              </div>
              </a>
            </Col>
            </Row>
            <Row>
            <Col sm="6" lg="6"  className="px-0 order-2 order-md-1">
            <NavLink to="/beyond-academics"  activeclassname="active">
            <div className="growthDiv">
                <div
                  className="growthImg2"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-3.jpg)`,
                  }}
                >
                  <div className="growthCaption">
                    <h4>beyond academics</h4>
                    <div  className="btn btn-success" activeclassname="active">Read More</div>
                  </div>
                </div>
              </div>
              </NavLink>
            </Col>
            <Col sm="6" lg="6" className="px-0 order-1 order-md-2">
            <NavLink to="/values"  activeclassname="active">

            <div className="growthDiv">
                <div
                  className="growthImg1"
                  style={{
                    backgroundImage: `url(assets/images/innovera/box-4.jpg)`,
                  }}
                >
                  <div className="growthCaption">
                    <h4>Values</h4>
                    {/* <Button className="btn btn-success" >Read More </Button> */}
                    <div   className="btn btn-success" >Read More</div>

                  </div>
                </div>
              </div>
              </NavLink>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Growth;
