import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import AcademicsPage from '../components/section/academics';
import Beyond from '../components/section/beyond_academics';
import Career from '../components/section/career';
import Cbse from '../components/section/cbse';
import DeliverPage from '../components/section/deliver_inspiration';
import ImpressionsPage from '../components/section/impressions';
import Infrastructure from '../components/section/infrastructure';
import LifeAtInnovera from '../components/section/life_at_innovera';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
 import { NavLink } from 'react-router-dom';
  import config from '../constant';
import SubHeader from '../components/layout/subheader';
import Sidebar from '../components/layout/sidebar';


class Expo21 extends Component {
    componentDidMount(){
        document.title = "Expo 2021 | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>

            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Expo 2021' subtitle=''/>
      <Container  >
      <Row>
      <Col lg="9">

        <Container   className=" growSection  impressions">
                 {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Grow</span>
            <span className="blueBor"> with us</span>
          </h1> */}
          
            <Row>
                  
                  <Col lg="12">
                  <h2  className="blueBg text-center  my-5  "  rounded>Expo 2021 </h2>
                  </Col>
  
                  </Row>
                <Row>        
                {[...Array(14)].map((e, i) => {
                             return      <Col lg='4'   key={i + 1} >
              <div className="productDiv" style={{  backgroundImage: `url(assets/images/innovera/expo_2021/${i+1 }.jpeg )`   }} >
               
				         </div>
              </Col>                        })}


                         </Row>
      
</Container>

         </Col>
         <Col lg="3">
         <Sidebar/>

         </Col>

         </Row>
         
         </Container>             <Footer/>


        </div>
    );

}
    }
export default Expo21;