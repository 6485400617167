import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
 import DatePicker from "react-datepicker";

 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';
 
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const ReferForm = () => {
 
       

        const [birthDate, setbirthDate] = useState(new Date().toLocaleDateString('fr-FR'));

        const [mailerState, setMailerState] = useState({
            name: "",
phone: "",
my_assoc: "",
refer_my: "",
refree_name: "",
referee_phone: "",
email: "",
more_info: "",
will_come: "",
call_him: ""
 
        });
        //const [select, setSelect] = useState([]);
        const [selectedOption, setSelectedOption] = useState('');
        const [showInput, setShowInput] = useState(false);
        const [mdata,setMdata]=useState([]);
        // {student_name:"",looking_addmission:"",present_school:""}



        
        const [val,setVal]=useState([]);

        const handleAdd=()=>{
            const abc=[...val,[]]
            setVal(abc)

            setMdata([...mdata,{student_name:"",looking_addmission:"", present_school:""}])

        }

        const handleDelete=(i)=>{
          const deletVal=[...val]
          deletVal.splice(i,1)
          setVal(deletVal)  
      }


      const handleChange=(e,i)=>{
        const {name,value}=e.target
        const onchangeVal = [...mdata]
        onchangeVal[i][name]=value
        setMdata(onchangeVal)
    }
        
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,

          }));
   
          if(e.target.name == 'more_info'){ 
          const selectedValue = e.target.value;
          setSelectedOption(selectedValue);
      
          // Determine whether to show or hide the input based on the selected value
          setShowInput(selectedValue === 'Yes');
        }
          // if (select.includes(e.target.value)) {
          //   setSelect((value) => value.filter((val) => val !== e.target.value));
          // } else {
          //   setSelect((value) => [...value, e.target.value]);
          // }
        }
    
        const submitEmail = async (e) => {
           e.preventDefault();
          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"refer_send/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState , birthDate ,mdata }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");

                toast.success("Message Sent!" , {autoClose: 2000})

 
              } else if (resData.status === "fail") {
               // alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                name: "",
phone: "",
my_assoc: "",
refer_my: "",
refree_name: "",
referee_phone: "",
email: "",
more_info: "",
will_come: "",
call_him: "",


              });
 
            });
            
        };
    
         return(
             <Container className="py-5">
              <ToastContainer />

                <Row>
                    <Col  lg={{span:8 , offset:2}} >
                    
                        <Form  onSubmit={submitEmail}>


                    <Row>
                      <Col  lg="6" >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Referrer Name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="Name " onChange={handleStateChange}
 name="name"  value={mailerState.name} />
                            </Form.Group>
                            </Col>

                            <Col  lg="6" >
                              
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            </Col>


                            <Col  lg="6" >
                              
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>My association with Innovera school as</Form.Label>
                                  
                                  <Form.Control type="text" required placeholder="Name " onChange={handleStateChange}
 name="my_assoc"  value={mailerState.my_assoc} />
                              </Form.Group>
                              </Col>


                              <Col  lg="6" >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>I would like to refer my
</Form.Label>
<Form.Select value={mailerState.refer_my} name="refer_my" aria-label="Default select example" onChange={handleStateChange}>

<option value=" ">Select any option</option>
<option value="Neighbour" defaultValue>Neighbour</option>
<option value="Relative">Relative</option>
<option value="friend">friend</option>
<option value="Acquaintances">Acquaintances</option>
<option value="customer"> customer</option>
</Form.Select>
                                </Form.Group>
                                </Col>

                                <Col  lg="6" >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Referee's Name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="Name " onChange={handleStateChange}
 name="refree_name"  value={mailerState.refree_name} />
                            </Form.Group>
                            </Col>

                            <Col  lg="6" >
                              
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Referee's Contact Number</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="referee_phone"  value={mailerState.referee_phone} placeholder="88888 55555" />
                            </Form.Group>
                            </Col>

<Col lg="6">
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Referee's Mail ID</Form.Label>
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
</Col>


<Col lg="6">
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label> would yo like to furnish more information
</Form.Label>
                                <Form.Select value={mailerState.more_info} name="more_info" aria-label="Default select example" onChange={handleStateChange}>

                                 
<option value=" "> Select</option>
<option value="Yes"> Yes</option>
<option value="No"> No</option>
                                </Form.Select>
                            </Form.Group>

</Col>
                                

                            </Row>

                            {showInput && (
  <>
                      {/* <Row>
                        <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Student's name</Form.Label>
                                <Form.Control type="text" required placeholder="Name " name="student_name" value={val.student_name} onChange={(e)=>handleChange(e,i)} />
                            </Form.Group>
                        </Col>
                        <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Looking for admission in class
</Form.Label>
<Form.Select value={mailerState.looking_addmission} name="looking_addmission[]" aria-label="Default select example" onChange={handleStateChange}>

<option value=" ">Select any option</option>
<option value="Nursery" defaultValue>Nursery</option>
<option value="JKG">JKG</option>
<option value="SKG">SKG</option>
<option value="1-10">1-10</option>
 </Form.Select>
</Form.Group>
                        </Col>
                        <Col lg="4">
                        
                        
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Present school name</Form.Label>
                                <Form.Control type="text" required placeholder="Name " name="present_school" value={val.present_school} onChange={(e)=>handleChange(e,i)} />
                                {/* onChange={handleStateChange}
 name="present_school[]"  value={mailerState.present_school} */}
                            {/* </Form.Group>
                            </Col>
                          </Row> */}  

                          <Row>

                            <Col lg="2">
                            <Form.Label>&nbsp;</Form.Label>

                              <Button type="button"  onClick={()=>handleAdd()}>+ Add Child</Button>
                            </Col>
                      </Row>


         {val.map((data,i)=>{
            return(

               <div key={i}>
{ i < 1 ? <hr></hr>
      : null  


} 
                     <Row>
                        <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Student's name</Form.Label>
                                {/*  onChange={handleStateChange}
 name="student_name[]"  value={mailerState.student_name}  */}
                                <Form.Control type="text" required placeholder="Name " name="student_name" value={val.student_name} onChange={(e)=>handleChange(e,i)} />
                            </Form.Group>

 
                        </Col>
                        <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Looking for admission in class
</Form.Label>
{/*  value={mailerState.looking_addmission} name="looking_addmission[]"  */}
<Form.Select aria-label="Default select example"   name="looking_addmission"  value={val.looking_addmission} onChange={(e)=>handleChange(e,i)}>

<option value=" ">Select any option</option>
<option value="Nursery" defaultValue>Nursery</option>
<option value="JKG">JKG</option>
<option value="SKG">SKG</option>
<option value="1-10">1-10</option>
 </Form.Select>
</Form.Group>
                        </Col>
                        <Col lg="4">
                        
                        
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Present school name</Form.Label>
                                <Form.Control type="text" required placeholder="Name "    name="present_school"  value={val.present_school} onChange={(e)=>handleChange(e,i)}/>
                            </Form.Group>
                            </Col>
                          </Row>
 
                      
                      <Row>

                  <Col lg="2">
                  <Form.Label>&nbsp;</Form.Label>

                    <Button  className='btn btn-danger'  onClick={()=>handleDelete(i)}>x</Button>
                    </Col>
                      </Row>
                      <hr></hr>

               </div>
            )
        })}


        <Row className='pt-3'>
        <Col lg="4">
                        
                        
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Visit School</Form.Label>
                                                             
 <DatePicker
        //selected={birthDate} 
          //  onChange={(date) => setbirthDate(date)}    
           
           onChange={(date) => {
            const d = new Date(date).toLocaleDateString('fr-FR');
            console.log(d);
            setbirthDate(d);
          }}
               selectsStart
        birthDate={birthDate}
        name="start_date"
        dateFormat="dd/MM/yyyy"
        className="form-control"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        value={birthDate}
         required
        autoComplete='off'
        placeholderText={'Please select a date'} 

       />
                            </Form.Group>
                            </Col>


                            <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>I would accompany with him/ her 
</Form.Label>
<Form.Select value={mailerState.will_come} name="will_come" aria-label="Default select example" onChange={handleStateChange}>

<option value=" ">Select any option</option>
<option value="Yes" defaultValue>Yes</option>
<option value="No">No</option>
 
 </Form.Select>
</Form.Group>
                        </Col>


                        <Col lg="4">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>counsellor may call him/ her:
</Form.Label>
<Form.Select value={mailerState.call_him} name="call_him" aria-label="Default select example" onChange={handleStateChange}>

<option value=" ">Select any option</option>
<option value="Yes" defaultValue>Yes</option>
<option value="No">No</option>
 
 </Form.Select>
</Form.Group>
                        </Col>
        </Row>
                    </>
                           
  )}

<Row className='pt-3'>
  <Col lg="12">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" className='btn btn-success'  >    Submit

    </button>
                            </Form.Group>
                            </Col>
                               </Row>
                            </Form>
                    </Col>
                   
                </Row>
            </Container>

            
         )
    }


export default ReferForm;