import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Beyond from '../components/section/beyond_academics';
import Cbse from '../components/section/cbse';
import WhyInnovera from '../components/section/why_innovera';
 import config from '../constant';
 import { Helmet, HelmetProvider } from "react-helmet-async";


class WhyInnoveraMain extends Component {
    // componentDidMount(){
    //     document.title = "Why Innovera | " + config.SIE_NAME
    //   }
        render(){
    return(
        <div>
            
<HelmetProvider>
             <meta charSet="utf-8" />
                <title> Innovera | Transparency for Parents, Confidence for Kids </title>
	<meta name="description" content="Maintaining transparency in the school with the parents is the most important part in building confidence. We encourage parents to come on a daily basis and audit the school on various parameters. They can spend five minutes to five hours" />
	<link rel="canonical" href="https://innoveraschool.com/why-innovera/?PSE" />   
   </HelmetProvider>
            <Header/>
            <WhyInnovera/>
             <Footer/>


        </div>
    );

}
    }
export default WhyInnoveraMain;