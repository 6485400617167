import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

class Prepare extends Component {
        render() {

    return(
        <div className='prepareSection' >
            <Container className="py-5 text-center">
            <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">we prepare</span>
            <span className="blueBor"> our students for</span>
          </h1>
                <Row>
                    <Col sm="6" lg="3"> 

                    {/* <div className='prepareDiv'>
                        <i className='fa fa-user'></i>
                    </div> */}
                    <Image src='assets/images/innovera/Green-icon-1.png' fluid/>
                    <h5>Tomorrows` leader</h5>
                     </Col>
                    <Col  sm="6" lg="3">
                    <Image src='assets/images/innovera/Green-icon-2.png' fluid/>

                    {/* <div className='prepareDiv'>
                        <i className='fa fa-user'></i>
                    </div>  */}
                    

                    <h5>Strong bonds</h5>
                            </Col>
                    <Col  sm="6" lg="3"> 
                    {/* <div className='prepareDiv'>
                        <i className='fa fa-user'></i>
                    </div>     */}
                     <Image src='assets/images/innovera/Green-icon-3.png' fluid/>
                    <h5>Critical thinkers</h5>
                  </Col>
                    <Col  sm="6" lg="3">
                    {/* <div className='prepareDiv'>
                        <i className='fa fa-user'></i>
                    </div>          */}
                     <Image src='assets/images/innovera/Green-icon-4.png' fluid/>
                    <h5>pioneering a change</h5>
                 </Col>
                </Row>
            </Container>
        </div>
    );
}
    }
export default Prepare;