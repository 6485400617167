import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import Parents from '../components/section/parents';
 import config from '../constant';


class ParentsMain extends Component {
    componentDidMount(){
        document.title = "Parents | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <Parents/>
             <Footer/>


        </div>
    );

}
    }
export default ParentsMain;