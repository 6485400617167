import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';

class Branches extends Component {
        render() {

    return(
        <div >
            <Container className="py-5 text-center">
             <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Our</span>
            <span className="greenBor"> Branches</span>
          </h1>                    
                <Row>
                <div className="col-sm-4 offset-sm-4 ">
        
        <div className="BranchBox">
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Innovera School 
         </h4>
         <p>Behind Samruddhi Tractors, Kadam Wak Wasti, Pune – Solapur, Pune, Maharashtra, 412201</p>
         <p><i className="fa fa-phone"></i> <a href="tel: +919168290611 "> 91682 90611 </a> |  <a href="tel: +917387332255"> 73873 32255</a></p>

     </div>
     </div>
                </Row>

                <Row>

                <div className="col-sm-4 offset-sm-4 ">
      <a href='https://mi-school.org/' target='_blank'>  
        <div className="BranchBox2" style={{minHeight:'130px'}}> 
             <Image src='assets/images/innovera/location.png' fluid />
         <h4>
         Innovera Pre-School - MI
         </h4>
         
     </div>
     </a>
     </div>
                </Row>

                {/* <Row>
                <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
 
         </h4>
         <p>Intisar Palace, Plot No.32, Near Sai Hospital, Bhosale village society, opp MSEB power house, Phursungi, Pune Maharashtra 412308
</p>
<p><i class="fa fa-phone"></i> <a href='tel:7218967709'>72189 67709</a> | <a href='tel:9860832577'>98608 32577</a> </p>

     </div>
     </div>

     <div className="col-sm-4">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids  
         </h4>
         <p>Flat No.1202, B-3, Grandbay Society, Solapur Highway, Manjri, Pune, Maharashtra 412307
</p>
<p><i class="fa fa-phone"></i> <a href='tel:9960159400'>99601 59400</a> </p>

     </div>
     </div>


     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids

         </h4>
         <p> Lane no 2, Ganaraj Park, Kalbhor Nagar, Kawadipath, Haveli, 
Pune, Maharashtra  
</p>
<p><i class="fa fa-phone"></i> <a href='tel:8055405146'>80554 05146</a> </p>
     </div> 
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Sr no 18/1d, House No 628, Gondhalenagar, Rakhumai Sankul, Hadapsar, Pune, Maharashtra 411028
</p>
<p><i class="fa fa-phone"></i> <a href='tel:7798204875'>77982 04875</a> </p>

     </div>
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Prime Point Rd, Nandini Takle Nagar, Manjri Bk, Pune, Maharashtra, 412307 </p>
         <p><i class="fa fa-phone"></i> <a href='tel:9960159400'>99601 59400</a> </p>

     </div>
     </div>

     <div className="col-sm-4 ">
        
        <div className="BranchBox2">
             <Image src='assets/images/innovera/office.png' fluid />
         <h4>
         Innovera Kids
  
         </h4>
         <p>Opposite Manjri Stud Farm, ( NH 9 ) Solapur Road, Manjri Greens Society, Manjri Bk, Pune, Maharashtra, 412307 </p>

         <p><i class="fa fa-phone"></i> <a href='tel:9422512705'>94225 12705</a> </p>

     </div>
     </div>
                </Row> */}


            
            </Container>
        </div>
    );
}
    }
export default Branches;