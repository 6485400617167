import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
 import axios from 'axios';

 import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';
 
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const CareerForm = () => {
 
       
     
        const [double, setDouble] = useState(false);

        const [mailerState, setMailerState] = useState({
          apply: "",
        });
    
        const [file, setFile] = useState();
        const [fileName, setFileName] = useState("");
   
        const saveFile = (e) => {
          setFile(e.target.files[0]);
          setFileName(e.target.files[0].name);
        };

        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));

 
        }
 
        const submitEmail = async (e) => {
          setDouble(true);

          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", fileName);
         formData.append("biodata", JSON.stringify({  mailerState }) );
 
          try {
            const res = await axios.post(
              config.BASE_URL+"career_send/",
              formData
            );
            console.log(res);
            toast.success("Message Sent!" , {autoClose: 2000})

          } catch (ex) {
            console.log(ex);
              toast.error("Message failed to send!", {autoClose: 2000})
              setMailerState({ apply: "" });
              setDouble(false);


          }
        };
    
        const submitEmail2 = async (e) => {
          setDouble(true);

           e.preventDefault();
           const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"career_send/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
              //'content-type': 'multipart/form-data',

            },
            body: JSON.stringify({ mailerState  }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");
                console.log(resData.data);

               // toast.success("Message Sent!" , {autoClose: 2000})

 
              } else if (resData.status === "fail") {
               // alert("Message failed to send");
              //  toast.success("Message failed to send!", {autoClose: 2000})
              console.log(resData.data);

              }
            })
            .then(() => {
              setMailerState({
                apply: "",
                
              });
              setDouble(false);

 
            });
            
        };
    
         return(
             <Container className="">
              <ToastContainer />

                <Row>
                    <Col lg="12">
                    
                        <Form   > 

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>You would like to apply for  ( eg. Admin Executive / Admin Sales)

</Form.Label>
                                <Form.Select value={mailerState.apply} name="apply" aria-label="Default select example" onChange={handleStateChange}>

                                  <option value=" ">Select Any Option</option>
                                 <option value="Teacher" defaultValue>Teacher
</option>
                                <option value="Admin">Admin
</option>
                                <option value="Coordinator"> Coordinator

</option>
<option value="Leadership role">Leadership Role </option>
<option value="Finance and Accounts">Finance and Accounts</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Upload Resume</Form.Label>
                                <Form.Control type="file"  required   onChange={saveFile}
 name="resume"  placeholder="Upload Resume" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="button"       disabled={double}
   onClick={() => {
    submitEmail();
    setDouble(true)
 }}  className='btn btn-success'  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                    </Col>
                   
                </Row>
            </Container>

            
         )
    }


export default CareerForm;