import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import VolunteerForm from './voluneer_form';
import config from '../../constant';
import ContactMainForm from './contact_main_form';
class Parents extends Component {
        render() {

    return(
        <div>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Ideal' subtitle='Parents'/>
            <Container  >
      <Row>
      <Col lg="9">
            <Container className="py-5  lifeAtInovera ParentsDiv">
            {/* <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">Ideal</span>
            <span className="greenBor"> Parents</span>
          </h1>                */}
            <Row>
                <Col sm="12" lg="12">
                     <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">Who is the Perfect Parent for Innovera ?</span>
           </h1>       
<h5> The one who - </h5> 
                    {/* <ul>

     <li>Believes that every child can have different reasons to attend school.</li>
<li>Believes that the school and you have to work collaboratively for the child.</li>
<li>Imagines attending a full-day school with the child.</li>
<li>Believes in a non-transactional relationship with the school.</li>
<li>Knows that academic abilities don't define anybody.</li>
<li>Picturises a day of school for their child on the ground.</li>
<li>Encourages their children to make efforts.</li>
<li>Is aware that conceptual learning has a greater impact than rote learning.</li>
<li>Gives equal importance to ranks and other activities.</li>
<li>If one of our values matches your thoughts, then we would be the perfect school for your child.
</li>

                    </ul> */}
 <Image src="assets/images/innovera/Innovera-Idea-Parents.jpg" className='d-none d-sm-block' fluid/>
<Image src="assets/images/innovera/Innovera-Idea-Parents-Mobile.jpg" className='d-sm-none d-xs-block' fluid/>
    
               
                   
                      </Col>

                     <Col sm="12" lg="12" className=" py-5  ">

                       <h1 className=" pb-5  mt-0 mb-0">
            <span className="blueBg">What parents</span>
            <span className="greenBor"> have to say</span>
          </h1>       


          <Row>
            <Col sm="6">
              {/* <iframe width="100%" height="200" src="https://www.youtube.com/embed/ntoW_-L0zb8" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

              <h4>Different Developmental Activities	</h4>
            <ul>
                   <li> Workshop for Parents on academic and non-academic topic	 </li> 
                   </ul>
            </Col>
            <Col sm="6">
              {/* <iframe width="100%" height="200" src="https://www.youtube.com/embed/uCA8QT2B0uM" title={config.SIE_NAME} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

              <h4>Parent Partnership </h4>
                <ul>
               <li> Parents desk   </li> 
               <li> Foster talks- for career guidance  </li> 
               <li> Volunteers at school Events- Night Camps, Dandiya	</li> 
                   <li> Parents Annual Sports Meet		</li>
                   </ul>
            </Col>
             
            </Row>        
                        {/* <Image src='assets/images/innovera/Banner_3.jpg' fluid/> */}
                        </Col>
            </Row>

          
           
             {/* <Row className='py-4'>
               <Col lg="6" sm="6" >
               <Image src='assets/images/innovera/parent/Image4.jpg' fluid/>
               <h4>Parents Get Together</h4>
               <li> Volunteers at school Events- Night Camps, Dandiya	</li> 
                   <li> Parents Annual Sports Meet		</li>
               </Col>
              
               <Col lg="6" sm="6" >
               <Image src='assets/images/innovera/parent/Image1.jpg' fluid/>
               <h4>Event for Parents	 </h4>
                <li> Personalised relationships due to home visits	   </li> 
               <li> Sessions on handing growing up children			</li> 

               
               </Col>
              </Row>  */}
   
              <Row className='py-4 d-none'>
                <Col lg="6" sm="6" >
               <Image src='assets/images/innovera/beyonds_academics/Making-Difference.jpg' fluid/>
               
               </Col>
   
              
    
               <Col lg='6' sm="6" >
               <Image src='assets/images/innovera/beyonds_academics/Strong-Bond.jpg' fluid/>
               
                </Col>
             </Row> 
               </Container>   
         </Col>
         <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact"  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Enroll now </NavLink></li>
<li><NavLink to="/parents-get-together"  className="bt-sidebar "> &nbsp;Parents Get Together </NavLink></li>
<li><NavLink to="/foster"  className="bt-sidebar "> &nbsp;Volunteer at School Events- Foster Talk & Parents as Educator
 </NavLink></li>
{/* <li><NavLink to="/annual-sport"  className="bt-sidebar "> &nbsp;Annual sports meet 
  </NavLink></li> */}
</ul>
</div>
<div className="sidebar-item link">
<h4>Call Us</h4>
<hr></hr>
 {/* <VolunteerForm/> */}
 <ContactMainForm/>
</div>
</div>


         </Col>
         </Row>
         </Container>

         </div>
 
    );
}
    }
export default Parents;