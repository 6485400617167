import React, { Component } from 'react';
import Header from '../components/layout/header';
import Contactform from '../components/section/contactform';
import Footer from '../components/layout/footer';
import Googlemap from '../components/section/googlemap';
import config from '../constant';



class Contact extends Component {

  
    componentDidMount(){
        document.title = "Contact | " + config.SIE_NAME
      }

    render(){
        return(
            <div>
            <Header/>   
             {/* <CardSec/>     */}
             <Contactform/>
             <Footer/>
            </div>
        )
    }
}

export default Contact;