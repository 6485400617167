import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Table from 'react-bootstrap/Table';

class Faculty extends Component {
        render() {

    return(
        <div>
            <Container className="py-5  faculty">
            <h1 className=" pb-5  mt-0 mb-0">
             <span className="blueBor"> FACULTIES</span>
          </h1>               
            <Row>
                <Col sm="6" lg="6">
                    <ul>
                        <li>New teachers have the greatest chance of success when they attend a range of learning and development trainings related to team building. </li>
                        <li>All the trainings help veteran teachers survive new difficulties in teaching.</li>
                        <li>It is critical for teachers to develop effective class management techniques so they can learn and produce new teaching approaches which will bring back the interest of their students and encourage learning. </li>
                        <li>LND training is offered by the school in order to let teachers share their views, suggestions, and questions with other teachers. </li>
                        <li>The teachers' bonding is increased as a result.</li>
                        <li>Cooking as a team-building activity can bring together people who enjoy cooking and also function as a relaxing and relational experience for the team</li>
                    </ul>
                     </Col>

                     <Col sm="6" lg="6">
                        <Image src='assets/images/innovera/Banner_3.jpg' fluid/>
                        </Col>
            </Row>

             
            </Container>
        </div>
    );
}
    }
export default Faculty;