import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

class NewsLetter extends Component {
  render() {
    return (
      <div className="newsletterSection" style={{  backgroundImage: `url(assets/images/innovera/Doodle-2.jpg)`, }}>
        <Container className="pb-5 text-justify">
          <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">News</span>
            <span className="blueBor"> letter</span>
          </h1>
          <Row>
            <Col sm="5" lg="5">
              <div class="d-grid gap-3">
                <div class="   ">
                  <h5>
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </h5>
                  <h6 className="text-muted">
                    <i>By Lorem ipsum </i>
                  </h6>
                </div>
                <div class="   ">
                  <h5>
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </h5>
                  <h6 className="text-muted">
                    <i>By Lorem ipsum </i>
                  </h6>
                </div>
                <div class="   ">
                  <h5>
                    Lorem ipsum dolor sit amet, consectetur adipisicing
                  </h5>
                  <h6 className="text-muted">
                    <i>By Lorem ipsum </i>
                  </h6>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 6, offset: 1 }}>
              <Image src="assets/images/innovera/Newletter.jpg" alt="" fluid />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default NewsLetter;
