import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Beyond from '../components/section/beyond_academics';
import Cbse from '../components/section/cbse';
 import config from '../constant';
 import { Helmet, HelmetProvider } from "react-helmet-async";


class BeyondMain extends Component {
    // componentDidMount(){
    //     document.title = "Beyond Academics | " + config.SIE_NAME
    //   }
        render(){
    return(
        <div>
            <HelmetProvider>
             <meta charSet="utf-8" />
                <title>Innovera School - A Place for Growth & Development </title>
	<meta name="description" content="Innovera School is a place where children can explore their strengths and learn about different environment stimuli. This will help them develop into strong and confident individuals." />
	<link rel="canonical" href="https://innoveraschool.com/?PSE" />   
   </HelmetProvider>
            <Header/>
            <Beyond/>
             <Footer/>


        </div>
    );

}
    }
export default BeyondMain;