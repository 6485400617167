import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
import Owlslider from '../components/section/owlslider';
import Values from '../components/section/values';
import config from '../constant';
import { Helmet, HelmetProvider } from "react-helmet-async";

class MainValues extends Component {
    // componentDidMount(){
    //     document.title = "Values | " + config.SIE_NAME
    //   }
        render(){
    return(
        <div>
            <HelmetProvider>
             <meta charSet="utf-8" />
                <title>  Innovera School - Values and Beliefs </title>
	<meta name="description" content="Innovera School is a values-driven school that believes in the power of education to change the world. We offer a well-rounded curriculum and an enriching environment that prepares students for success." />
	<link rel="canonical" href="https://innoveraschool.com/values/?PSE" />   
   </HelmetProvider>
            <Header/>
            <Values/>
             <Footer/>


        </div>
    );

}
    }
export default MainValues;