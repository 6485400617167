import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const valueText = [
   
    {
        imgUrl: "/assets/images/innovera/values/Life-long-learner.png",
        heading:"Be a life long learner",
        desc:"To develop leaders who are informed and well-equipped to make a difference in the world."
    },
    {
        imgUrl: "/assets/images/innovera/values/Think-Out-of-the-box.png",
        heading:"Think out of the box",
        desc:"We encourage to think outside the box for innovative and creative, and not be bound by rules or conventions."
    },
     
    {
        imgUrl: "/assets/images/innovera/values/Be-Approchable.png",
        heading:"Be Approachable",
        desc:"Being approachable to everyone and taking decisions that benefit everyone is what we value the most."
    },
    {
        imgUrl: "/assets/images/innovera/values/Be-solution-provider.png",
        heading:"Be a solution provider",
        desc:"To help our students achieve their full potential, we help in providing long term and replicable solutions."
    },
    {
        imgUrl: "/assets/images/innovera/values/Use-freedom-responsiblity.png",
        heading:"Use freedom responsibly",
        desc:"We, as team are aware of the magnitude of responsibility that comes with our freedom."
    },
    {        
        imgUrl: "/assets/images/innovera/values/Build-Strong-Relationship.png",
        heading:"Build strong relationships",
        desc:"To be in a position to help students learn and develop relationships with each other and the people around them."
    },
    {
        imgUrl: "/assets/images/innovera/values/Take-care-of-others.png",
        heading:"Take care of others so that they feel safe",
        desc:"We inculcate the emotions of caring from the very beginning of our stakeholders' lives."
    },
    {
        imgUrl: "/assets/images/innovera/values/Have-fun-while-at-work.png",
        heading:"Have fun while at work",
        desc:"Which includes higher happiness quotient in the school enviroment."
    },
    {
        imgUrl: "/assets/images/innovera/values/Be-clear-in-communication.png",
        heading:"Be clear in communication and maintain transparency",
        desc:" Execution is based on transparent communication with all stakeholders - parents, children and team members."
    },
   
]
class Values extends Component {
        render() {

    return(
        <div>
            <Container className="py-5 text-center valueIcons">
                 <h1 className=" pb-5  mt-0 mb-0">
            <span className="greenBg">Our</span>
            <span className="blueBor"> Values</span>
          </h1>
                <Row>

                    {
                        valueText.map( (value , i) => (
                    <Col sm="6" lg="4" key={i}>  
                    <Image src={ value.imgUrl} alt="" fluid />
                    <h4>  { value.heading}</h4>
                    <p>{ value.desc}</p>
                        {/* <div className="circular-sb">
                        { value.heading}
                        <span className="circDesc"> { value.desc}</span>
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            
                        </div> */}
                    </Col>
                     ) ) }

                     
                </Row>

                <Row>
                    <Col lg={{span:6, offset:3}} className='text-center'>

                 <a href="./assets/images/innovera/SLIDE-9-The-Culture-Book.pdf" download>   <div class="BranchBox3  text-center">DOWNLOAD OUR CULTURE BOOK </div></a>
                    
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
    }
export default Values;