import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import SubHeader from '../layout/subheader';

class Apply extends Component {
        render() {

    return(
        <div>
                         <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Apply ' subtitle=' '/>

            <Container className="py-5 text-center applySection">
            {/* <h1 className=" pb-5  mt-0 mb-0">
             <span className="greenBor"> Apply</span>
          </h1>         */}
          
                   <Row className="mb-5">
                     <Col sm="6" lg="6">
                     <a href="https://erp.innoveraschool.com/site/userlogin" target='_blank'   activeclassname="active">
                    <div className='BranchBox  '>
                    APPLY OR CHECK YOUR CURRENT APPLICATION <br></br> (current families)
                    </div>
                    </a>

                    </Col>
                    <Col sm="6" lg="6">
                    <NavLink to="/enroll"   activeclassname="active">
                    <div className='BranchBox3  '>
                    APPLY NOW<br></br>  (new families)
                    </div>
                    </NavLink>
                    </Col>
                   
                        </Row>
                        <Row>
                        <Col sm="12" lg="12">
                        <p><b>Innovera school</b>  is a fun and exciting space for exploration. We attempt to make the relationship of the school with its parents as open and transparent as much possible. The application process and admission process is simple and easy as possible. If you already believe <b>Innovera school</b>  is the best educational option for your child, feel free to contact us.</p>
                          </Col>
                        </Row>
            </Container>
        </div>
    );
}
    }
export default Apply;