import React, { Component } from 'react';
import Header from '../components/layout/header';
import Contactform from '../components/section/contactform';
import Footer from '../components/layout/footer';
import Googlemap from '../components/section/googlemap';
import config from '../constant';
import EnquiryForm from '../components/section/enquiryform';
import { Col, Container, Row } from 'react-bootstrap';


 
class Franchise extends Component {

  
    componentDidMount(){
        document.title = "Franchise | " + config.SIE_NAME
      }

    render(){
        return(
            <div>
            <Header/>   
             {/* <CardSec/>     */}

             <div className='py-5'>
                <Container>
                    <Row>
                        <Col lg={{span:8, offset:2}}>

                        <h1 className=" pb-5  mt-0 mb-0"> <span className="blueBor"> Franchise Form</span></h1>
             <EnquiryForm/>
             </Col>
             </Row>
             </Container>
             </div>

             <Footer/>
            </div>
        )
    }
}

export default Franchise;