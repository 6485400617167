import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Contactform from './contactform';
import ContactMainForm from './contact_main_form';
import { Link } from "react-scroll";
import VisitMainform from './visitForm';


class Visit extends Component {
        render() {

    return(
        <div>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Visit' subtitle=' '/>
            <Container>
      <Row>
      <Col lg="9">
             <Container className="py-5  visitBox">
            {/* <h1 className=" pb-5  mt-0 mb-0">
             <span className="greenBor"> Visit</span>
          </h1>         */}
          
          <Row >
                <Col lg="12" className='text-center'>
                <h2  className="blueBg mb-4  "  rounded>WE WELCOME YOU TO VISIT US</h2>

                <h4>Thank you for your interest in INNOVERA SCHOOL </h4>

                <h4>We invite you and your family to visit our school  </h4>
                
                </Col>

                </Row>

                   <Row className='pt-4 text-center'  >
                     <Col sm="6" lg="6">
                    <div className='BranchBox'>
                    We believe that when a parent is thinking of
taking admission for their child in
Innovera School, he/she should be convinced
with the values and culture of the school.

                    </div>
                    </Col>
                    <Col sm="6" lg="6">
                    <div className='BranchBox3'>
                    For this reason we always encourage parents to visit our campus and interact with the team. This ensures transparency and effective communication between school and parents.

                    </div>

                    </Col>
                    <Col sm="12" lg="12">
                    <div className='BranchBox'>
                    Transparency is one of our core values and we integrate this culture not only with new admissions or inquiries; but also with existing students and parents. For this, we have a PARENTS DESK in which any parent can visit the school and stay on campus for 5 minutes to 5 hours. During their visit, they may sit in classrooms with students, observe hygiene in washrooms and provide feedback before leaving campus.

                    </div>
                    </Col>

                 
                     
                   
                        </Row>

                        <Row>
                        <Col sm="12" lg="12" className=' growSection'>

                        <h2  className="blueBg  text-center"  rounded>Schedule a Visit</h2>

                    <VisitMainform/>
                    </Col>
                        </Row>
                        
            </Container>
            </Col>
          <Col lg="3">
         <div className=" py-5 services-details-area">

<div className="sidebar-item link">
<h4>Related Links</h4>
<ul><hr></hr>
<li> <NavLink to="/contact "  className="bt-sidebar"> &nbsp;Enquire Now  </NavLink>  </li>

<li><NavLink to="/enroll"  className="bt-sidebar "> &nbsp;Admission Form  </NavLink></li>
<li><Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={100}
            className="bt-sidebar" activeclassname="active" style={{textTransform:'initial'}}      >  &nbsp;Book an Appointment </Link></li>
<li><NavLink to="/contact"  className="bt-sidebar "> &nbsp;Request for call back

 </NavLink></li>
<li><NavLink to="/team"  className="bt-sidebar "> &nbsp;Meet up the Academic team

  </NavLink></li>
</ul>
</div>


<div className="sidebar-item link" id='contact'>
<h4>Call Us</h4>
<hr></hr>
 <ContactMainForm/>
</div>
</div>

 
         </Col>
         </Row>
         </Container>

         </div>    );
}
    }
export default Visit;