import React, { Component }  from 'react';
import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutUs from '../components/section/about_us';
  import config from '../constant';

 import Container from 'react-bootstrap/Container';
 import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
 import SubHeader from '../components/layout/subheader';


class Terms extends Component {
    componentDidMount(){
        document.title = "Terms & Conditions | " + config.SIE_NAME
      }
        render(){
    return(
        <div>
            <Header/>
            <SubHeader BkImg = 'assets/images/innovera/Banner_3.jpg'  Title='Terms & Conditions' subtitle=''/>
            <div className='ServiceHeading privacyPolicy py-5'>
            <Container  >
      <Row>
      <Col lg="12">
        <h3>General Rules & Guidelines</h3>
      
<h4>Admissions</h4>
<p>New admissions will be made subject to availability of seats.</p>

<p>The school management reserves the right to admit or retain students in the school.
</p>

<h4>Attendance</h4>

<p>A minimum of 75% of the total attendance of the academic session would be necessary to appear for the final examination and promotion. Shortage of attendance up to 15% may be condoned in case of genuine illness provided an authentic medical certificate is submitted.</p>
<p>If a student fails to appear for any test, examination, practical, project work, he /she will be considered as failed in that test, examination, practical, project work. No retest / re exam will be conducted. If the absence is due to serious illness then an application for such leave supported with a medical report should be submitted to the Principal and an average of the student’s performance will be calculated.</p>


<h4>School Uniform & Appearance</h4>
<p>Students are expected to dress and behave in a manner appropriate to the membership of the school and should observe the prescribed uniform regulations throughout the school day and on occasions as announced by the school.</p>
<p>Neat and tidy school uniforms recommended by the school with identity cards must be worn daily by the students. Students without the same will not be permitted in.</p>

<h4>Ban on Use of Mobile Phones</h4>
<p>Mobile and other portable devices like Ipads, Ipods, and Tablets etc. shall not be allowed to be used or seen in the school campus at any time. The school shall not be responsible for any incidences of theft or devices getting misplaced or misused. Also, all such devices, if found, will be confiscated by a member of the school staff and will only be returned back to parents later by the Principal only after calling them to the school.</p>

<h4>General Rules</h4>
<p>There is one fundamental school rule, that all students at all times will behave well and sensibly and show courtesy, consideration and respect for others and do nothing that will bring the school's name into disrepute.</p>
<p>The school reserves the right to make changes in the School Rules, which will be communicated to students and parents and shall be binding to all.
<p>All School Rules apply to all students from the time they leave home for school until they have returned home at the end of the school day, or while taking part in any activity organised by, or related to the school.</p>
<p>Although the school is not responsible for the behaviour of its students outside its premises, due notice will be taken of reports of misbehavior and disciplinary action will be    taken against them if the report is substantiated on proper enquiry.</p>
<p>Irregular attendance at school, physical violence in school or school bus, misbehaviour with fellows students, habitual laziness, disobedience, dishonesty or   conduct injurious to the moral tone of the school  are sufficient reasons for the dismissal of a student. In such cases the decision of the Principal shall be final.</p>
<p>Any damage done in the class or in the school premises has to be made good. Pupils must take care of the equipment that has been lent for their use.</p>
<p>The school authorities will not be responsible for the loss, damage or robbery of pupil's belongings. It is not advisable for the pupils to bring money or valuable articles unnecessarily to school.</p>
<p>Students are expected to carry books as per the daily time table. No books (other than text or school library books), Newspapers or periodicals may be brought to the school without the Principal's permission.</p>
<p></p>No pupil is exempted from Games and Physical Training without a Medical Certificate. The period of exemption should be specified.</p>
<p>Pupils of this school are strictly forbidden to buy anything from the street hawkers. Students are expected to bring homemade vegetarian food in their tiffins and are allowed.</p>
<p>Spoken English is encouraged in the school and within the school premises.</p>
<p>No gift or any demonstration to the teacher or any other staff is allowed. (Even on birthdays, Children's day, Teacher’s day, Diwali, etc.)</p>

<h4>Parent Co-Operation</h4>
<p>Parents are expected to support and reinforce the school rules and policies. The school expects parents to attend parent-teacher meetings, school functions and thus show interest in the student's school activities.</p>
<p>Parents, guardians or other persons are not allowed to speak to the students or their teachers during class hours / during transition without the permission of the Principal. Appointments may be made before hand to interact during a teacher's free period, with the approval of the Principal.</p>
<p>Parents are expected to co-operate in the work of the school by enforcing regularity and discipline and by taking general interest in the child's progress.</p>
<p> Parents are requested to cooperate with the school in all its activities including planning / organizing / volunteering / sponsoring / compering events, annual day, sports day, other programmes etc as and when required. Parents are also expected to voluntarily extend their expertise and knowledge in their respective fields for the betterment of students and school, if the school seeks for the same.</p>

<h4>Withdrawal</h4>
<p>The Leaving Certificate, without which a pupil cannot join any other school, shall be refused to those who have not paid their fees and other dues.</p>
<p>The Leaving Certificate should be applied for in writing by the parent or guardian in the prescribed form along with No Due Certificates from the Accts. Dept and Library. A month's notice must be given before a pupil leaves the school.</p>
      

        </Col>
        </Row>
        </Container>
        </div>

              <Footer/>


        </div>
    );

}
    }
export default Terms;